import go, { Diagram } from 'gojs'
import { ReactDiagram } from 'gojs-react'
import { RefObject } from 'react'

import { DiagramTemplateType } from '@/components/Diagram/Templates/enums'
import { getModelAsObject } from '@/components/Diagram/utils'
import { StructureDtoRedux } from '@/store/modules/node'
import { LinkData, NodeData } from '@/types'
import { NativeMap } from '@/utils'

import { updateDiagramProperty } from './utils'

export const updateSpecificNodeHeaderColor = (
	diagramRef: RefObject<ReactDiagram>,
	nodeKey: number,
	updatedColor: string,
) => {
	updateDiagramProperty(diagramRef, 'update node color', (model) => {
		const nodeData = model.findNodeDataForKey(nodeKey)

		if (!nodeData) {
			return
		}

		return model.setDataProperty(nodeData, 'nodeHeaderColor', updatedColor)
	})
}

export const updateSpecificNodeBodyColor = (
	diagramRef: RefObject<ReactDiagram>,
	nodeKey: number,
	updatedColor: string,
) => {
	updateDiagramProperty(diagramRef, 'update node color', (model) => {
		const nodeData = model.findNodeDataForKey(nodeKey)

		if (!nodeData) {
			return
		}

		return model.setDataProperty(nodeData, 'nodeBodyColor', updatedColor)
	})
}

export const removeNode =
	(diagramRef: React.RefObject<ReactDiagram>) => (nodeId: number) => {
		const instanceDiagram = diagramRef.current?.getDiagram()
		if (!instanceDiagram) {
			throw new Error('Diagram instance is null')
		}

		const model = instanceDiagram.model as go.GraphLinksModel
		const nodeData = model.findNodeDataForKey(nodeId)
		if (!nodeData) {
			throw new Error(`Node with ID ${nodeId} was not found`)
		}
		instanceDiagram.startTransaction('remove node')

		// Remove node
		model.removeNodeData(nodeData)

		// Remove links related to the node
		const linksFromNode = instanceDiagram.findLinksByExample({ from: nodeId })
		linksFromNode.each((link) => {
			model.removeLinkData(link.data)
		})

		const linksToNode = instanceDiagram.findLinksByExample({ to: nodeId })
		linksToNode.each((link) => {
			model.removeLinkData(link.data)
		})

		instanceDiagram.commitTransaction('remove node')
	}

export const addDiagramNode = (
	diagramRef: RefObject<ReactDiagram>,
	addedNodeId: number,
	droppedNode: any,
	linksForNode: any[],
	loc: string,
) => {
	const instanceDiagram = diagramRef?.current?.getDiagram()
	const model = instanceDiagram?.model as go.GraphLinksModel
	const parsedModel = getModelAsObject(instanceDiagram as Diagram)

	const isNodeDuplicate = parsedModel?.nodeDataArray.find(
		(node: NodeData) => node.key === addedNodeId,
	)

	if (isNodeDuplicate) return

	instanceDiagram?.startTransaction('new node')

	model?.addNodeData({
		key: droppedNode.id,
		text: droppedNode.name,
		category: DiagramTemplateType.Table,
		loc: loc,
	})

	linksForNode.forEach((link: LinkData) => {
		const existingLinks = model.linkDataArray
		const linkExists = existingLinks.some(
			(existingLink) =>
				existingLink.from === link.from && existingLink.to === link.to,
		)

		if (!linkExists) {
			model.addLinkData({
				from: link.from,
				to: link.to,
				text: link.text,
			})
		}
	})

	instanceDiagram?.commitTransaction('new node')
}
export const addNewTable =
	(
		getAllNodesFromTree: NativeMap<StructureDtoRedux>,
		setFolderNode: (nodeData: StructureDtoRedux | undefined) => void,
		nodeId: number,
		setIsAddNewNode: (isAddNewNode: boolean) => void,
	) =>
	() => {
		const fldrNode = getAllNodesFromTree[nodeId]
		if (!fldrNode) {
			throw new Error(`Node with ID ${nodeId} was not found`)
		}
		setFolderNode(fldrNode)
		setIsAddNewNode(true)
	}
