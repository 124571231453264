import go from 'gojs'
import { ReactDiagram } from 'gojs-react'

import { StructureDto } from '@/endpoints/models'
import { TableTab } from '@/enums'
import { useAppDispatch } from '@/hooks'
import { RootState } from '@/store'
import { StructureDtoRedux } from '@/store/modules/node'
import { openTab } from '@/store/modules/tab/actions'
import { selectTableTab } from '@/store/modules/table/actions'
import colors from '@/styles/diagramColors'
import { LinkData } from '@/types/diagrams'
import { NativeMap } from '@/utils'

import { ValidationError, ValidationErrorType } from './types'

const $ = go.GraphObject.make

export const initPalette = () => {
	const palette = $(go.Palette)

	palette.layout = $(go.GridLayout, { wrappingColumn: 1 })
	palette.maxSelectionCount = 1

	return palette
}

export const getNodePath = (
	allNodesFromTree: NativeMap<StructureDtoRedux>,
	nodeId: number,
): string => {
	const acc: string[] = []

	const buildPath = (currentId: number) => {
		const node = allNodesFromTree[currentId]
		if (!node) {
			return
		}
		if (node.parentStructureId) {
			buildPath(node.parentStructureId)
		}
		if (node.name !== undefined) {
			acc.push(node.name)
		}
	}

	buildPath(nodeId)
	return acc.join(' > ')
}

export const getLinksByNodeId = (
	state: RootState,
	nodeId: number,
): LinkData[] => {
	let matchingLinks: LinkData[] = []

	Object.values(state.folder.folders).forEach((folder) => {
		if (
			folder &&
			folder.form &&
			folder.form.newDiagram &&
			folder.form.newDiagram.linkDataArray
		) {
			const links = folder.form.newDiagram.linkDataArray.filter(
				(link) => link.from === nodeId || link.to === nodeId,
			)
			matchingLinks = matchingLinks.concat(links)
		}
	})

	return matchingLinks
}

/**

Marks validation errors in the diagram. For nodes and links

related to the error, the 'changed' property is set to true.

@param diagram - The current instance of the diagram.

@param validationErrors - Array of validation errors.
*/
export const markChanges = (
	diagram: ReactDiagram | null,
	validationErrors: ValidationError[],
) => {
	if (diagram) {
		const instanceDiagram = diagram.getDiagram()
		const model = instanceDiagram?.model as go.GraphLinksModel

		if (model && instanceDiagram) {
			instanceDiagram.startTransaction('mark changes')

			validationErrors.forEach((error) => {
				const { type, nodeId } = error

				// Mark changed nodes
				const node = model.findNodeDataForKey(nodeId)
				if (
					node &&
					(type === ValidationErrorType.NODE_NOT_EXIST ||
						type === ValidationErrorType.TEXT_MISMATCH)
				) {
					model.setDataProperty(node, 'hasChanged', true)
					model.setDataProperty(node, 'bodyColor', colors.nodes.hasChanged)
				}

				// Mark changed links
				const link = model.findLinkDataForKey(nodeId)
				if (
					link &&
					(type === ValidationErrorType.NODE_NOT_EXIST ||
						type === ValidationErrorType.TEXT_MISMATCH ||
						type === ValidationErrorType.LINK_INVALID)
				) {
					model.setDataProperty(link, 'hasChanged', true)
				}
			})

			instanceDiagram.commitTransaction('mark changes')
		}
	}
}

export const serializeDiagramToSvg = (diagram: go.Diagram) => {
	const diagramSvg = diagram.makeSvg()
	return new XMLSerializer().serializeToString(diagramSvg as SVGAElement)
}

const useOpenTable = (data: StructureDto) => {
	const dispatch = useAppDispatch()
	dispatch(openTab(data, false))
}

export const useHandleData = (data: StructureDto, isDoubleClick: boolean) => {
	const dispatch = useAppDispatch()
	useOpenTable(data)
	if (isDoubleClick) {
		setTimeout(() => {
			dispatch(selectTableTab(data, TableTab.Constraints))
		}, 2000)
	}
}

export const deleteNode =
	(
		getAllNodesFromTree: NativeMap<StructureDtoRedux>,
		setShowDeleteModal: (show: boolean) => void,
		setNodeToDelete: (node: StructureDtoRedux | undefined) => void,
	) =>
	(nodeId: number) => {
		setShowDeleteModal(true)
		const nodeToDelete = getAllNodesFromTree[nodeId]
		if (!nodeToDelete) {
			throw new Error(`Node with ID ${nodeId} was not found`)
		}
		setNodeToDelete(nodeToDelete)
	}

export const getModelAsObject = (diagram: go.Diagram) => {
	const modelAsJson = diagram?.model?.toJson()
	return modelAsJson ? JSON.parse(modelAsJson) : undefined
}
