import go from 'gojs'

import { TranslationHelper } from '@/context/Locale'

export const useContextMenuTemplate = (
	goJsDiagram: go.Diagram,
	addNewTable: () => void,
	selectedDiagramInfo: (value: boolean) => void,
	t: TranslationHelper,
) => {
	const $ = go.GraphObject.make

	goJsDiagram.contextMenu = $(
		'ContextMenu',
		$(
			'ContextMenuButton',
			{
				click: () => addNewTable(),
			},
			$(go.TextBlock, t('DIAGRAM_ADD_TABLE')),
		),
	)
	goJsDiagram.addDiagramListener('BackgroundSingleClicked', function (e) {
		selectedDiagramInfo(true)
	})
}
