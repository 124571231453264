import { faPlus } from '@fortawesome/free-solid-svg-icons'

import { Button, DialogWrapper, TabsActionsBar } from '@/components'
import { StructureDto } from '@/endpoints/models'
import { useAppContext, useTableColumns } from '@/hooks'
import { DiagramsTable } from '@/pages/User/pages/Home/pages/FolderDetail/DiagramsTable'
import { useDiagramsTableColumns } from '@/pages/User/pages/Home/pages/FolderDetail/UseDiagramsTableColumns'
import { OpenedFolderData } from '@/store/modules/folder/types'
import { MainContentWrap } from '@/styles'

import { AddDiagram } from '../components/AddDiagram'

interface DiagramsProps {
	data: OpenedFolderData
	editMode: boolean
	node: StructureDto
}
export const DiagramsTab = ({ node, editMode, data }: DiagramsProps) => {
	const { t } = useAppContext()

	const diagramsData = data?.form?.diagrams || []

	const columns = useTableColumns({
		tableData: diagramsData,
		properties: useDiagramsTableColumns(),
	})

	const dialog = (opened: boolean, onClose: () => void) =>
		opened && (
			<AddDiagram
				onClose={() => {
					onClose()
				}}
				node={node}
				editMode={editMode}
			/>
		)

	return (
		<>
			<TabsActionsBar>
				<DialogWrapper dialog={dialog}>
					{(onClick) => (
						<Button
							icon={faPlus}
							onClick={onClick}
							schema="success"
							coloredIcon={true}
							disabled={!editMode}
						>
							{t('ADD_NEW_DIAGRAM')}
						</Button>
					)}
				</DialogWrapper>
			</TabsActionsBar>

			<MainContentWrap>
				<DiagramsTable
					columns={columns}
					data={diagramsData}
					editMode={editMode}
					hasLastRowEdit={true}
					node={node}
				/>
			</MainContentWrap>
		</>
	)
}
