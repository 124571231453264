import go from 'gojs'
import { ReactDiagram } from 'gojs-react'
import { RefObject } from 'react'

export const updateDiagramProperty = (
	diagramRef: RefObject<ReactDiagram>,
	transactionName: string,
	updateFn: (model: go.Model) => void,
) => {
	const diagram = diagramRef.current?.getDiagram()
	if (!diagram) {
		throw new Error('Diagram instance is null')
	}

	diagram.startTransaction(transactionName)
	updateFn(diagram.model)
	diagram.commitTransaction(transactionName)
}
