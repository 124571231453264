import { TranslationHelper } from '@/context/Locale'
import { StructureDto, StructureObjectDto } from '@/endpoints/models'
import {
	addNode,
	addNodeInDiagramContextMenu,
	addSystemNode,
} from '@/store/modules/node/actions'

export const getAction = (
	data: { data: string; type: StructureObjectDto.TypeEnum },
	node: StructureDto,
	type: StructureObjectDto.TypeEnum,
	diagramProps?: {
		diagram: any
		isDiagramAddModal?: boolean
		node: StructureDto
		selectedTabDetailId: number
	},
) => {
	const { selectedTabDetailId, diagram, isDiagramAddModal } = diagramProps || {}

	if (
		type !== StructureObjectDto.TypeEnum.SYSTEM &&
		!diagramProps?.isDiagramAddModal
	) {
		return addNode(node.id, data)
	} else if (
		type !== StructureObjectDto.TypeEnum.SYSTEM &&
		diagramProps?.isDiagramAddModal
	) {
		return addNodeInDiagramContextMenu(node.id, data, {
			diagram,
			isDiagramAddModal,
			node,
			selectedTabDetailId,
		})
	}

	return addSystemNode(data)
}

export const getHeader = (
	type: StructureObjectDto.TypeEnum,
	t: TranslationHelper,
) => {
	switch (type) {
		case StructureObjectDto.TypeEnum.SYSTEM:
			return t('ADD_NEW_SYSTEM')
		case StructureObjectDto.TypeEnum.FOLDER:
			return t('ADD_NEW_FOLDER')
		case StructureObjectDto.TypeEnum.TABLE:
			return t('ADD_NEW_TABLE')
		case StructureObjectDto.TypeEnum.VIEW:
			return t('ADD_NEW_VIEW')
		case StructureObjectDto.TypeEnum.MAPPING:
			return t('ADD_NEW_MAPPING')
		case StructureObjectDto.TypeEnum.WORKFLOW:
			return t('ADD_NEW_WORKFLOW')
		case StructureObjectDto.TypeEnum.API_NODE:
			return t('ADD_NEW_API_NODE')
		case StructureObjectDto.TypeEnum.API_COLLECTION:
			return t('ADD_NEW_API_COLLECTION')
		case StructureObjectDto.TypeEnum.MAPPING_READ:
			return t('ADD_NEW_MAPPING_READ')
		case StructureObjectDto.TypeEnum.MAPPING_WRITE:
			return t('ADD_NEW_MAPPING_WRITE')
		case StructureObjectDto.TypeEnum.QUEUE:
			return t('ADD_NEW_QUEUE')
		default:
			return undefined
	}
}
