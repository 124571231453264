import { faPlus } from '@fortawesome/free-solid-svg-icons'

import { ModalForm } from '@/components/Modal/ModalForm'
import { TextAreaFormField, TextFormField } from '@/components/UberForm'
import { StructureDto } from '@/endpoints/models'
import { LinkRoutingType } from '@/enums'
import { useAppContext, useAppDispatch } from '@/hooks'
import { addNewDiagram, saveFolder } from '@/store/modules/folder/actions'

interface AddDiagramProps {
	editMode: boolean
	node: StructureDto
	onClose: () => void
}

interface AddDiagramFormProps {
	description?: string
	name: string
}
export const AddDiagram = ({ node, onClose }: AddDiagramProps) => {
	const { t } = useAppContext()
	const dispatch = useAppDispatch()

	const handleSubmit = async (form: AddDiagramFormProps) => {
		const { name, description } = form

		const newDiagramPayload = {
			name,
			description,
			properties: {
				grid: {
					isVisible: true,
					cellSize: 26,
					lineColors: {
						intervalHorizontal: '#2e3838',
						intervalVertical: '#332e2e',
						vertical: '#f7e9e9',
						horizontal: '#d5c8df',
					},
				},
				displayOptions: {
					tableName: true,
					tableCode: false,
					pkColumnsName: true,
					pkColumnsCode: true,
					columnsName: true,
					columnsCode: true,
					comments: true,
					description: true,
				},
				node: {
					header: {
						color: '#7e99a0',
					},
					body: {
						color: '#feffd1',
					},
				},
				links: {
					color: '',
					routingType: LinkRoutingType.Orthogonal,
				},
			},
			class: 'GraphLinksModel',
			linkKeyProperty: 'key',
			nodeDataArray: [],
			linkDataArray: [],
		}

		dispatch(addNewDiagram(node.id, newDiagramPayload))
		await dispatch(saveFolder(node))
		onClose()
	}

	return (
		<ModalForm
			saveTitle={t('CREATE')}
			saveIcon={faPlus}
			cancelTitle={t('CANCEL')}
			onSubmit={handleSubmit}
			open={true}
			onClose={onClose}
			contentStyle={{
				width: '25rem',
			}}
			header={t('ADD_NEW_DIAGRAM')}
		>
			<TextFormField
				title={t('NAME')}
				name="name"
				required
				autoFocus
				enableAutocomplete={false}
			/>

			<TextAreaFormField title={t('DESCRIPTION')} name="description" />
		</ModalForm>
	)
}
