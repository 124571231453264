import { useAppContext } from '@/hooks'

import Button from '../../Button/Button'
import { Controls } from '../styles'

interface DiagramControlsProps {
	onAutoLayout: () => void
	onExportPng: () => void
	onExportSvg: () => void
}

export const DiagramControls: React.FC<DiagramControlsProps> = ({
	onExportPng,
	onExportSvg,
	onAutoLayout,
}) => {
	const { t } = useAppContext()

	return (
		<Controls>
			<Button schema="default" onClick={onExportPng}>
				{t('EXPORT_PNG')}
			</Button>
			<Button schema="default" onClick={onExportSvg}>
				{t('EXPORT_SVG')}
			</Button>
			<Button schema="default" onClick={onAutoLayout}>
				{t('DIAGRAM_AUTO_LAYOUT')}
			</Button>
		</Controls>
	)
}
