import { GraphLinksModel } from 'gojs'
import { ReactDiagram } from 'gojs-react'
import { RefObject } from 'react'

import { updateDiagramProperty } from './utils'

export const updateSpecificLink = (
	diagramRef: RefObject<ReactDiagram>,
	inputType: string,
	linkKey: number,
	updatedColor: string,
) => {
	updateDiagramProperty(diagramRef, 'update link color', (model) => {
		// Ensure the model is of type GraphLinksModel
		if (!(model instanceof GraphLinksModel)) {
			console.error('Model is not a GraphLinksModel')
			return
		}

		const linkData = model.linkDataArray.find((link) => link.key === linkKey)
		if (!linkData) {
			console.error('No link found with the specified key')
			return
		}

		switch (inputType) {
			case 'SPECIFIC_LINK_COLOR':
				return model.setDataProperty(linkData, 'linksColor', updatedColor)
			case 'SPECIFIC_LINK_LABEL_COLOR':
				return model.setDataProperty(linkData, 'linkLabelColor', updatedColor)
			default:
				break
		}
	})
}
