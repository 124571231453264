import { debounce } from 'lodash'
import { ChangeEvent, useEffect, useMemo, useState } from 'react'

import { updateSpecificLink } from '@/components/Diagram/handlers/linkHandlers'
import { useAppContext, useAppStore } from '@/hooks'
import { RootState } from '@/store'

import { LinkPropertiesProps } from '../types'
import {
	DiagramPropertiesContent,
	PropertyType,
} from './DiagramPropertiesContent'

interface LinkLocalPropertiesProps {
	specificLinkColor: string
}

export const LinkProperties = ({
	selectedLink,
	diagramRef,
	propertiesWidth,
	handlePropertiesTitle,
	nodeId,
	selectedTabDetailId,
}: LinkPropertiesProps) => {
	const [localLinkProperties, setlocalLinkProperties] =
		useState<LinkLocalPropertiesProps>()

	const getAllNodesFromTree = useAppStore(
		(state: RootState) => state?.node?.nodes,
	)

	const diagrams = useAppStore(
		(state) => state.folder.folders[nodeId]?.form.diagrams,
	)
	const selectedDiagram = diagrams?.find(
		(diagram) => diagram.id === selectedTabDetailId,
	)

	const thisLink = selectedDiagram?.linkDataArray.find(
		(link) => link.key === selectedLink.key,
	)

	const { t } = useAppContext()
	handlePropertiesTitle(t('DIAGRAM_LINK_PROPERTIES'))

	const debouncedUpdateSpecificLink = debounce(updateSpecificLink, 500)

	const onChange = (e: ChangeEvent<HTMLInputElement>, inputType: string) => {
		debouncedUpdateSpecificLink(
			diagramRef,
			inputType,
			selectedLink.key,
			e.target.value,
		)
	}

	useEffect(() => {
		setlocalLinkProperties({
			specificLinkColor: thisLink?.linksColor as string,
		})
	}, [thisLink?.linksColor])

	const contents = useMemo(
		() => [
			{
				id: 1,
				label: t('DIAGRAM_NODE_ID'),
				contentValue: selectedLink.key,
				type: PropertyType.Info,
			},
			{
				id: 2,
				label: t('DIAGRAM_NODE_TEXT'),
				contentValue: selectedLink.text,
				type: PropertyType.Info,
			},
			{
				id: 3,
				label: t('DIAGRAM_NODE_FROM'),
				contentValue: getAllNodesFromTree[selectedLink.to]?.name,
				type: PropertyType.Info,
			},
			{
				id: 4,
				label: t('DIAGRAM_NODE_TO'),
				contentValue: getAllNodesFromTree[selectedLink.from]?.name,
				type: PropertyType.Info,
			},
			{
				id: 5,
				label: t('DIAGRAM_NODE_TYPE'),
				contentValue: 'Link',
				type: PropertyType.Info,
			},
			{
				id: 6,
				label: t('DIAGRAM_NODE_CONSTRAINT_COLOR'),
				contentValue: (
					<input
						type="color"
						value={localLinkProperties?.specificLinkColor}
						onChange={(e) => onChange(e, 'SPECIFIC_LINK_COLOR')}
					/>
				),
				inputType: 'color',
				type: PropertyType.Constraint,
			},
		],
		[selectedLink, localLinkProperties],
	)

	return (
		<DiagramPropertiesContent
			contents={contents}
			propertiesWidth={propertiesWidth}
		/>
	)
}
