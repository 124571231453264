import go from 'gojs'
import { ReactDiagram } from 'gojs-react'
import { RefObject } from 'react'

import { updateDiagramProperty } from '@/components/Diagram/handlers/utils'

// Function to update node properties
const updateNodes = (
	diagramRef: RefObject<ReactDiagram>,
	properties: Record<string, any>,
) => {
	updateDiagramProperty(diagramRef, 'update nodes properties', (model) => {
		model.nodeDataArray.forEach((nodeData) => {
			for (const [prop, value] of Object.entries(properties)) {
				model.setDataProperty(nodeData, prop, value)
			}
		})
	})
}

// Function to update link properties
export const updateLinks = (
	diagramRef: RefObject<ReactDiagram>,
	properties: Record<string, any>,
) => {
	updateDiagramProperty(diagramRef, 'update links properties', (model) => {
		;(model as go.GraphLinksModel).linkDataArray.forEach((linkData) => {
			for (const [prop, value] of Object.entries(properties)) {
				model.setDataProperty(linkData, prop, value)
			}
		})
	})
}

// Specific update functions using the generic handlers
export const updateNodeHeaderColor = (
	diagramRef: RefObject<ReactDiagram>,
	color: string,
) => {
	updateNodes(diagramRef, { nodeHeaderColor: color })
}

export const toggleTableCode = (
	diagramRef: RefObject<ReactDiagram>,
	isTableCodeVisible: boolean,
) => {
	updateNodes(diagramRef, { tableCodeVisibility: isTableCodeVisible })
}

export const toggleTableName = (
	diagramRef: RefObject<ReactDiagram>,
	isTableNameVisible: boolean,
) => {
	updateNodes(diagramRef, { tableNameVisibility: isTableNameVisible })
}

export const updateNodeBodyColor = (
	diagramRef: RefObject<ReactDiagram>,
	color: string,
) => {
	updateNodes(diagramRef, { nodeBodyColor: color })
}

export const updateLinksColor = (
	diagramRef: RefObject<ReactDiagram>,
	color: string,
) => {
	updateLinks(diagramRef, { linksColor: color })
}

export const toggleLinksRoutingType = (
	diagramRef: RefObject<ReactDiagram>,
	routingType: string,
) => {
	const diagram = diagramRef.current?.getDiagram()
	if (!diagram) {
		throw new Error('Diagram instance is null')
	}

	diagram?.model?.startTransaction('change routing type')
	diagram?.links?.each((link) => {
		link.routing =
			routingType === 'orthogonal' ? go.Link.Orthogonal : go.Link.Normal
	})
	diagram?.model?.commitTransaction('change routing type')

	updateLinks(diagramRef, { routingType })
}

// New toggleGrid function to update the diagram grid visibility
export const toggleGrid = (
	diagramRef: RefObject<ReactDiagram>,
	isGridVisible: boolean,
) => {
	const diagram = diagramRef.current?.getDiagram()
	if (!diagram) {
		throw new Error('Diagram instance is null')
	}

	diagram.startTransaction('toggle grid visibility')
	diagram.grid.visible = isGridVisible
	diagram.commitTransaction('toggle grid visibility')
}

export const updateGridCellSize = (
	diagramRef: React.RefObject<ReactDiagram>,
	size: number,
) => {
	const diagram = diagramRef.current?.getDiagram()
	if (diagram) {
		diagram.startTransaction('update grid cell size')
		diagram.grid.gridCellSize = new go.Size(size, size)
		diagram.commitTransaction('update grid cell size')
	}
}

export const updateGridLineColors = (
	diagramRef: RefObject<ReactDiagram>,
	{ color, type }: { color: string; type: string },
) => {
	const diagram = diagramRef.current?.getDiagram()

	if (!diagram) {
		throw new Error('Diagram instance is null')
	}

	diagram.startTransaction('update grid colors')

	const updateLineColor = (lineName: string, color: string) => {
		const line = diagram.grid.findObject(lineName) as go.Shape
		if (line && color) {
			line.stroke = color
		}
	}

	switch (type) {
		case 'GRID_H_LINE_INPUT':
			updateLineColor('HorizontalLine', color)
			break
		case 'GRID_V_LINE_INPUT':
			updateLineColor('VerticalLine', color)
			break
		case 'GRID_INTERVAL_H_LINE_INPUT':
			updateLineColor('IntervalHorizontalLine', color)
			break
		case 'GRID_INTERVAL_V_LINE_INPUT':
			updateLineColor('IntervalVerticalLine', color)
			break
		default:
			throw new Error(`Unknown line type: ${type}`)
	}

	diagram.commitTransaction('update grid colors')
}
