import { createContext, useContext } from 'react'

import { StructureDto } from '@/endpoints/models'

interface DiagramContextProps {
	diagram: any
	isEditMode: boolean
	node: StructureDto
	selectedTabDetailId: number
}

export const DiagramContext = createContext<DiagramContextProps>({
	diagram: null,
	isEditMode: false,
	node: {},
	selectedTabDetailId: 0,
})

// Hook to use the context
export const useDiagramContext = () => {
	const context = useContext(DiagramContext)
	if (!context) {
		throw new Error('useDiagramContext must be used within a DiagramProvider')
	}
	return context
}

DiagramContext.displayName = 'DiagramContext'
