import { ChangeEvent, useEffect, useMemo, useState } from 'react'

import { useAppContext, useAppStore } from '@/hooks'
import { RootState } from '@/store'

import {
	updateSpecificNodeBodyColor,
	updateSpecificNodeHeaderColor,
} from '../handlers/nodeHandlers'
import { LocalSpecificNodeProps, NodePropertiesProps } from '../types'
import { getNodePath } from '../utils'
import {
	DiagramPropertiesContent,
	PropertyType,
} from './DiagramPropertiesContent'

export const NodeProperties = ({
	selectedNode,
	diagramRef,
	propertiesWidth,
	handlePropertiesTitle,
	selectedTabDetailId,
	nodeId,
}: NodePropertiesProps) => {
	const getAllNodesFromTree = useAppStore(
		(state: RootState) => state?.node?.nodes,
	)

	const diagrams = useAppStore(
		(state) => state.folder.folders[nodeId]?.form.diagrams,
	)
	const selectedDiagram = diagrams?.find(
		(diagram) => diagram.id === selectedTabDetailId,
	)

	const thisNode = selectedDiagram?.nodeDataArray.find(
		(node) => node.key === selectedNode.key,
	)

	const [localNodeProperties, setLocalNodeProperties] =
		useState<LocalSpecificNodeProps>()

	const onChange = (e: ChangeEvent<HTMLInputElement>, inputType: string) => {
		switch (inputType) {
			case 'SPECIFIC_NODE_HEADER_COLOR':
				return updateSpecificNodeHeaderColor(
					diagramRef,
					selectedNode.key,
					e.target.value,
				)

			case 'SPECIFIC_NODE_BODY_COLOR':
				return updateSpecificNodeBodyColor(
					diagramRef,
					selectedNode.key,
					e.target.value,
				)

			default:
				break
		}
	}
	const { t } = useAppContext()
	handlePropertiesTitle(t('DIAGRAM_NODE_PROPERTIES'))

	const { specificNodeHeaderColor, specificNodeBodyColor } =
		localNodeProperties || {}

	const contents = useMemo(
		() => [
			{
				id: 1,
				label: t('DIAGRAM_NODE_ID'),
				contentValue: selectedNode.key,
				type: PropertyType.Info,
				isGrouped: true,
			},
			{
				id: 2,
				label: t('DIAGRAM_NODE_NAME'),
				contentValue: selectedNode.text,
				type: PropertyType.Info,
				isGrouped: true,
			},
			{
				id: 3,
				label: t('DIAGRAM_NODE_TYPE'),
				contentValue: selectedNode.category,
				type: PropertyType.Info,
				isGrouped: true,
			},
			{
				id: 4,
				label: t('DIAGRAM_NODE_FULL_PATH'),
				contentValue:
					getNodePath(getAllNodesFromTree, selectedNode.key) ||
					t('DIAGRAM_NODE_DELETED'),
				type: PropertyType.Info,
			},
			{
				id: 5,
				label: t('DIAGRAM_NODE_HEADER_COLOR'),
				contentValue: (
					<input
						type="color"
						value={specificNodeHeaderColor}
						onChange={(e) => onChange(e, 'SPECIFIC_NODE_HEADER_COLOR')}
					/>
				),
				type: PropertyType.Table,
			},
			{
				id: 6,
				label: t('DIAGRAM_NODE_BODY_COLOR'),
				contentValue: (
					<input
						type="color"
						value={specificNodeBodyColor}
						onChange={(e) => onChange(e, 'SPECIFIC_NODE_BODY_COLOR')}
					/>
				),
				type: PropertyType.Table,
			},
		],
		[
			t,
			selectedNode.key,
			selectedNode.text,
			selectedNode.category,
			getAllNodesFromTree,
			specificNodeHeaderColor,
			specificNodeBodyColor,
		],
	)

	useEffect(() => {
		setLocalNodeProperties({
			specificNodeHeaderColor: thisNode?.nodeHeaderColor,
			specificNodeBodyColor: thisNode?.nodeBodyColor,
		})
	}, [selectedNode, thisNode])

	return (
		<DiagramPropertiesContent
			contents={contents}
			propertiesWidth={propertiesWidth}
		/>
	)
}
