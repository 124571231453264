import { cloneDeep, debounce, merge } from 'lodash'
import { useCallback, useEffect, useMemo, useState } from 'react'

import {
	DiagramPropertiesContent,
	PropertyType,
} from '@/components/Diagram/DiagramPropertiesPanel/DiagramPropertiesContent'
import { RoutingTypeSelector } from '@/components/Diagram/DiagramPropertiesPanel/RoutingTypeSelector'
import { useAppContext, useAppStore } from '@/hooks'

import {
	toggleGrid,
	toggleLinksRoutingType,
	toggleTableCode,
	toggleTableName,
	updateGridCellSize,
	updateGridLineColors,
	updateLinksColor,
	updateNodeBodyColor,
	updateNodeHeaderColor,
} from '../handlers'
import {
	DiagramPropertiesProps,
	LineColorType,
	LocalDiagramPropertiesProps,
} from '../types'

export const DiagramProperties = ({
	handlePropertiesTitle,
	diagramRef,
	saveProperties,
	nodeId,
	selectedTabDetailId,
}: DiagramPropertiesProps) => {
	const { t } = useAppContext()

	handlePropertiesTitle(t('DIAGRAM_PROPERTIES'))

	const diagrams = useAppStore(
		(state) => state.folder.folders[nodeId]?.form.diagrams,
	)
	const selectedDiagram = diagrams?.find(
		(diagram) => diagram.id === selectedTabDetailId,
	)

	const [localProperties, setLocalProperties] =
		useState<LocalDiagramPropertiesProps>()

	// Sync local state with Redux state on component mount or selectedDiagram change
	useEffect(() => {
		setLocalProperties({
			nodeHeaderColor: selectedDiagram?.properties?.node?.header?.color,
			nodeBodyColor: selectedDiagram?.properties?.node?.body?.color,
			linksColor: selectedDiagram?.properties?.links?.color,
			linksRoutingType: selectedDiagram?.properties?.links?.routingType,
			isGridVisible: selectedDiagram?.properties?.grid?.isVisible,
			isTableCodeVisible:
				selectedDiagram?.properties?.displayOptions?.tableCode,
			isTableNameVisible:
				selectedDiagram?.properties?.displayOptions?.tableName,
			gridCellSize: selectedDiagram?.properties?.grid?.cellSize,
			gridHorizontalLineColor:
				selectedDiagram?.properties?.grid?.lineColors?.horizontal,
			gridVerticalLineColor:
				selectedDiagram?.properties?.grid?.lineColors?.vertical,
			gridIntervalVLineColor:
				selectedDiagram?.properties?.grid?.lineColors?.intervalVertical,
			gridIntervalHLineColor:
				selectedDiagram?.properties?.grid?.lineColors?.intervalHorizontal,
		})
	}, [selectedDiagram?.properties])

	const saveDebounced = useCallback(
		debounce((updatedProps) => {
			const newProperties = merge({}, selectedDiagram?.properties, updatedProps)
			saveProperties?.(newProperties)
		}, 200),
		[selectedDiagram?.properties],
	)

	const handleGridLineColorChange = (type: LineColorType, color: string) => {
		const updatedProps = cloneDeep(selectedDiagram?.properties)

		if (updatedProps?.grid?.lineColors) {
			updatedProps.grid.lineColors[type] = color
		}

		saveDebounced(updatedProps)
	}
	const handleInputChange = (inputType: string, value: any) => {
		switch (inputType) {
			case 'NODE_HEADER_INPUT':
				saveDebounced({ node: { header: { color: value } } })
				return updateNodeHeaderColor(diagramRef, value)
			case 'TABLE_CODE_INPUT':
				saveDebounced({ displayOptions: { tableCode: value } })
				return toggleTableCode(diagramRef, value)
			case 'TABLE_NAME_INPUT':
				saveDebounced({ displayOptions: { tableName: value } })
				return toggleTableName(diagramRef, value)
			case 'NODE_BODY_INPUT':
				saveDebounced({ node: { body: { color: value } } })
				return updateNodeBodyColor(diagramRef, value)
			case 'LINKS_COLOR_INPUT':
				saveDebounced({ links: { color: value } })
				return updateLinksColor(diagramRef, value)
			case 'ROUTING_TYPE_INPUT':
				saveDebounced({ links: { routingType: value } })
				return toggleLinksRoutingType(diagramRef, value)
			case 'GRID_TOGGLE_INPUT':
				saveDebounced({ grid: { isVisible: value } })
				return toggleGrid(diagramRef, value)
			case 'GRID_SIZE_INPUT':
				saveDebounced({ grid: { cellSize: value } })
				return updateGridCellSize(diagramRef, value)
			case 'GRID_H_LINE_INPUT':
				handleGridLineColorChange('horizontal', value)

				return updateGridLineColors(diagramRef, {
					color: value,
					type: 'GRID_H_LINE_INPUT',
				})
			case 'GRID_V_LINE_INPUT':
				handleGridLineColorChange('vertical', value)

				return updateGridLineColors(diagramRef, {
					color: value,
					type: 'GRID_V_LINE_INPUT',
				})
			case 'GRID_INTERVAL_H_LINE_INPUT':
				handleGridLineColorChange('intervalHorizontal', value)

				return updateGridLineColors(diagramRef, {
					color: value,
					type: 'GRID_INTERVAL_H_LINE_INPUT',
				})

			case 'GRID_INTERVAL_V_LINE_INPUT':
				handleGridLineColorChange('intervalVertical', value)

				return updateGridLineColors(diagramRef, {
					color: value,
					type: 'GRID_INTERVAL_V_LINE_INPUT',
				})
			default:
				break
		}
	}

	const {
		nodeHeaderColor,
		nodeBodyColor,
		linksColor,
		isGridVisible,
		gridCellSize,
		gridHorizontalLineColor,
		gridVerticalLineColor,
		gridIntervalVLineColor,
		gridIntervalHLineColor,
		isTableCodeVisible,
		isTableNameVisible,
		linksRoutingType,
	} = localProperties || {}

	const contents = useMemo(
		() => [
			{
				id: 1,
				label: t('DIAGRAM_NODE_HEADER_COLOR'),
				contentValue: (
					<input
						type="color"
						value={nodeHeaderColor}
						onChange={(e) =>
							handleInputChange('NODE_HEADER_INPUT', e.target.value)
						}
					/>
				),
				type: PropertyType.Table,
			},
			{
				id: 2,
				label: t('DIAGRAM_NODE_COLUMNS_BACKGROUND'),
				contentValue: (
					<input
						type="color"
						value={nodeBodyColor}
						onChange={(e) =>
							handleInputChange('NODE_BODY_INPUT', e.target.value)
						}
					/>
				),
				type: PropertyType.Table,
			},
			{
				id: 3,
				label: t('DIAGRAM_NODE_CONSTRAINT_COLOR'),
				contentValue: (
					<input
						type="color"
						value={linksColor}
						onChange={(e) =>
							handleInputChange('LINKS_COLOR_INPUT', e.target.value)
						}
					/>
				),
				type: PropertyType.Constraint,
			},
			{
				id: 4,
				label: t('DIAGRAM_GRID_SWITCH'),
				contentValue: (
					<input
						type="checkbox"
						checked={isGridVisible}
						onChange={() =>
							handleInputChange('GRID_TOGGLE_INPUT', !isGridVisible)
						}
					/>
				),
				type: PropertyType.Grid,
				isGrouped: true,
			},
			...(selectedDiagram?.properties?.grid?.isVisible
				? [
						{
							id: 5,
							label: t('DIAGRAM_GRID_CELL_SIZE'),
							contentValue: (
								<input
									type="range"
									min="5"
									max="50"
									value={gridCellSize || 10}
									onChange={(e) =>
										handleInputChange('GRID_SIZE_INPUT', Number(e.target.value))
									}
								/>
							),
							type: PropertyType.Grid,
							isGrouped: true,
						},
						{
							id: 6,
							label: t('DIAGRAM_GRID_HORIZONTAL_LINE'),
							contentValue: (
								<input
									type="color"
									value={gridHorizontalLineColor}
									onChange={(e) =>
										handleInputChange('GRID_H_LINE_INPUT', e.target.value)
									}
								/>
							),
							type: PropertyType.Grid,
						},
						{
							id: 7,
							label: t('DIAGRAM_GRID_VERTICAL_LINE'),
							contentValue: (
								<input
									type="color"
									value={gridVerticalLineColor}
									onChange={(e) =>
										handleInputChange('GRID_V_LINE_INPUT', e.target.value)
									}
								/>
							),
							type: PropertyType.Grid,
						},
						{
							id: 8,
							label: t('DIAGRAM_GRID_INTERVAL_HORIZONTAL_LINE'),
							contentValue: (
								<input
									type="color"
									value={gridIntervalHLineColor}
									onChange={(e) =>
										handleInputChange(
											'GRID_INTERVAL_H_LINE_INPUT',
											e.target.value,
										)
									}
								/>
							),
							type: PropertyType.Grid,
						},
						{
							id: 9,
							label: t('DIAGRAM_GRID_INTERVAL_VERTICAL_LINE'),
							contentValue: (
								<input
									type="color"
									value={gridIntervalVLineColor}
									onChange={(e) =>
										handleInputChange(
											'GRID_INTERVAL_V_LINE_INPUT',
											e.target.value,
										)
									}
								/>
							),
							type: PropertyType.Grid,
						},
					]
				: []),
			{
				id: 10,
				label: t('DIAGRAM_TABLE_CODE'),
				contentValue: (
					<input
						type="checkbox"
						checked={isTableCodeVisible}
						onChange={() =>
							handleInputChange('TABLE_CODE_INPUT', !isTableCodeVisible)
						}
					/>
				),
				type: PropertyType.Display,
			},
			{
				id: 11,
				label: t('DIAGRAM_TABLE_NAME'),
				contentValue: (
					<input
						type="checkbox"
						checked={isTableNameVisible}
						onChange={() =>
							handleInputChange('TABLE_NAME_INPUT', !isTableNameVisible)
						}
					/>
				),
				type: PropertyType.Display,
			},
		],
		[localProperties, t],
	)

	return (
		<>
			<DiagramPropertiesContent contents={contents} propertiesWidth={350} />
			<RoutingTypeSelector
				routingType={linksRoutingType as string}
				onChange={(e) =>
					handleInputChange('ROUTING_TYPE_INPUT', e.target.value)
				}
				t={t}
			/>
		</>
	)
}
