import { useEffect, useState } from 'react'

import { useAppContext, useAppStore } from '@/hooks'
import { RootState } from '@/store'
import { LinkData, NodeData } from '@/types/diagrams'

import {
	ValidateDiagramProps,
	ValidationError,
	ValidationErrorType,
} from '../types'

export const useReactDiagramValidate = ({
	diagram,
	onValidationErrors,
	setShowModal,
	onModelChange,
}: ValidateDiagramProps) => {
	const [validationErrors, setValidationErrors] = useState<ValidationError[]>(
		[],
	)
	const { t } = useAppContext()
	const getAllNodesFromTree = useAppStore(
		(state: RootState) => state?.node?.nodes,
	)

	const validateNodeExistence = (
		node: NodeData,
		errors: ValidationError[],
		uniqueErrorMessages: Set<string>,
		validNodeKeys: Set<number>,
	) => {
		const checkedNode = getAllNodesFromTree[node.key]
		if (!checkedNode) {
			const message = t('DIAGRAM_VALIDATION_TABLE_NOT_EXIST', [node.text])
			errors.push({
				type: ValidationErrorType.NODE_NOT_EXIST,
				nodeId: node.key,
				nodeName: node.text,
				message: message,
			})
			uniqueErrorMessages.add(message)
		} else {
			validNodeKeys.add(node.key)
		}
	}

	const validateNodeText = (
		node: NodeData,
		errors: ValidationError[],
		uniqueErrorMessages: Set<string>,
	) => {
		const checkedNode = getAllNodesFromTree[node.key]

		if (!checkedNode || !node.text) return
		if (checkedNode && checkedNode.name !== node.text.trim()) {
			const message = t('DIAGRAM_VALIDATION_TEXT_MISMATCH', [node.text])
			errors.push({
				type: ValidationErrorType.TEXT_MISMATCH,
				nodeId: node.key,
				nodeName: node.text,
				message: message,
			})
			uniqueErrorMessages.add(message)
		}
	}

	const validateLinksInDiagram = (
		link: LinkData,
		errors: ValidationError[],
		uniqueErrorMessages: Set<string>,
		validNodeKeys: Set<number>,
	) => {
		if (!validNodeKeys.has(link.from) || !validNodeKeys.has(link.to)) {
			const message = t('DIAGRAM_VALIDATION_LINK_NOT_EXIST', [link.text])
			errors.push({
				type: ValidationErrorType.LINK_INVALID,
				nodeId: link.key,
				nodeName: link.text,
				message: message,
			})
			uniqueErrorMessages.add(message)
		}
	}

	const validateTablesInDiagram = () => {
		if (diagram.ref.current) {
			const modelAsJson = diagram.ref.current.getDiagram()?.model.toJson()
			if (modelAsJson !== undefined) {
				const modelAsObject = JSON.parse(modelAsJson)
				const diagramNodes = modelAsObject.nodeDataArray
				const diagramLinks = modelAsObject.linkDataArray
				const errors: ValidationError[] = []
				const uniqueErrorMessages = new Set<string>()
				const validNodeKeys = new Set<number>()

				diagramNodes.forEach((node: NodeData) => {
					validateNodeExistence(
						node,
						errors,
						uniqueErrorMessages,
						validNodeKeys,
					)
					validateNodeText(node, errors, uniqueErrorMessages)
				})

				diagramLinks.forEach((link: LinkData) => {
					validateLinksInDiagram(
						link,
						errors,
						uniqueErrorMessages,
						validNodeKeys,
					)
				})

				setValidationErrors(errors)
				onValidationErrors(errors)
			}
		} else {
			console.warn(t('DIAGRAM_WARNING'))
		}
	}

	const startDiagramTransaction = (instanceDiagram: go.Diagram) => {
		if (!instanceDiagram) {
			setShowModal(false)
			return
		}

		const model = instanceDiagram.model as go.GraphLinksModel
		if (!model) {
			setShowModal(false)
			return
		}

		instanceDiagram.startTransaction('update diagram')
	}

	const endDiagramTransaction = (instanceDiagram: go.Diagram) => {
		instanceDiagram.commitTransaction('update diagram')
		onModelChange()
		setShowModal(false)
	}

	const handleNodeNotExist = (
		model: go.GraphLinksModel,
		instanceDiagram: go.Diagram,
		nodeId: number,
	) => {
		const nodeData = model.findNodeDataForKey(nodeId)
		if (nodeData) {
			model.removeNodeData(nodeData)

			const linksFromNode = instanceDiagram.findLinksByExample({ from: nodeId })
			linksFromNode.each((link) => {
				model.removeLinkData(link.data)
			})

			const linksToNode = instanceDiagram.findLinksByExample({ to: nodeId })
			linksToNode.each((link) => {
				model.removeLinkData(link.data)
			})
		}
	}

	const handleTextMismatch = (model: go.GraphLinksModel, nodeId: number) => {
		const checkedNode = getAllNodesFromTree[nodeId]
		const nodeData = model.findNodeDataForKey(nodeId)
		if (checkedNode && nodeData) {
			nodeData.text = checkedNode.name
			model.setDataProperty(nodeData, 'changed', false)
			model.updateTargetBindings(nodeData)
		}
	}

	const handleLinkInvalid = (model: go.GraphLinksModel, linkId: number) => {
		const linkData = model.findLinkDataForKey(linkId)
		if (linkData) {
			model.removeLinkData(linkData)
		}
	}

	const handleUpdateDiagram = () => {
		if (!diagram.ref.current) {
			setShowModal(false)
			return
		}

		const instanceDiagram = diagram.ref.current.getDiagram()
		if (!instanceDiagram) {
			setShowModal(false)
			return
		}
		const model = instanceDiagram.model as go.GraphLinksModel
		startDiagramTransaction(instanceDiagram)

		validationErrors.forEach((error: ValidationError) => {
			const { type, nodeId } = error
			switch (type) {
				case ValidationErrorType.NODE_NOT_EXIST:
					handleNodeNotExist(model, instanceDiagram, nodeId)
					break
				case ValidationErrorType.TEXT_MISMATCH:
					handleTextMismatch(model, nodeId)
					break
				case ValidationErrorType.LINK_INVALID:
					handleLinkInvalid(model, nodeId)
					break
				default:
					break
			}
		})

		endDiagramTransaction(instanceDiagram)
	}

	useEffect(() => {
		validateTablesInDiagram()
	}, [diagram.ref.current, getAllNodesFromTree])

	return {
		validateTablesInDiagram,
		validationErrors,
		handleUpdateDiagram,
	}
}
