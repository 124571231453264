import debounce from 'debounce'
import { ReactDiagram } from 'gojs-react'
import { RefObject, useCallback } from 'react'
import { useDispatch } from 'react-redux'

import {
	getModelAsObject,
	serializeDiagramToSvg,
} from '@/components/Diagram/utils'
import { StructureDto } from '@/endpoints/models'
import {
	removeDiagramNode,
	saveFolder,
	updateDiagram,
	updateLinkPropDiagram,
	updatePropDiagram,
} from '@/store/modules/folder/actions'
import { DiagramModifiedValues, DiagramProperties } from '@/types'

export const useDiagramChangeHandler = (
	diagramRef: RefObject<ReactDiagram>,
	node: StructureDto,
	selectedTabDetailId: number,
) => {
	const dispatch = useDispatch()
	const saveDebounced = debounce(() => dispatch(saveFolder(node)), 1000)

	const onModelChange = useCallback(
		(
			diagramModifiedValues: DiagramModifiedValues,
			panelProperties: DiagramProperties,
		) => {
			const {
				modifiedNodeData,
				modifiedLinkData,
				removedNodeKeys,
				insertedLinkKeys,
				insertedNodeKeys,
			} = diagramModifiedValues || {}
			const diagramInstance = diagramRef?.current?.getDiagram()

			if (!diagramInstance) {
				console.error('Diagram instance is null')
				return
			}

			const modifiedDiagramModel = getModelAsObject(diagramInstance)

			console.log(
				'useDiagramChangeHandler: modifiedDiagramModel',
				modifiedDiagramModel,
			)
			const diagramSvg = serializeDiagramToSvg(diagramInstance)

			const payload = {
				diagramSvg,
				modifiedDiagramModel,
				selectedTabDetailId,
				panelProperties,
			}

			if (modifiedNodeData?.length > 0) {
				console.log('useDiagramChangeHandler: modified nodes', modifiedNodeData)
				dispatch(updateDiagram(node, payload))
				saveDebounced()
			}

			if (modifiedLinkData?.length > 0) {
				console.log('useDiagramChangeHandler: modified links', modifiedLinkData)

				const payload = {
					nodeId: node.id,
					selectedTabDetailId,
					data: modifiedLinkData?.[0],
				}
				dispatch(updateLinkPropDiagram(payload))
				saveDebounced()
			}

			if (insertedNodeKeys?.length > 0) {
				saveDebounced()
			}

			if (removedNodeKeys?.length > 0) {
				console.log('useDiagramChangeHandler: removedNodeKeys', removedNodeKeys)
				dispatch(
					removeDiagramNode(node.id, selectedTabDetailId, removedNodeKeys[0]),
				)
				saveDebounced()
			}

			if (panelProperties) {
				console.log('useDiagramChangeHandler: panelProperties', panelProperties)
				dispatch(updatePropDiagram(node, payload))
				saveDebounced()
			}
		},
		[],
	)

	return onModelChange
}
