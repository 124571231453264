import * as go from 'gojs'

import { DiagramTemplateType } from '@/components/Diagram/Templates/enums'
import { getNodePath } from '@/components/Diagram/utils'
import { StructureDtoRedux } from '@/store/modules/node'
import colors from '@/styles/diagramColors'
import { AllKeysFromNodes } from '@/types/diagrams'
import { NativeMap } from '@/utils'

// Constants for shapes
const shapes = {
	rectangle: 'Rectangle',
	lineH: 'LineH',
}

export const getAllColumns = (
	allKeysFromNodes: AllKeysFromNodes[],
	nodeId: number,
): { code: string; name: string }[] => {
	const node = allKeysFromNodes.find((node) => node.id === nodeId)

	if (!node || !node.columns) {
		return Array(4).fill({ code: '', name: '', isPrimaryKey: false })
	}

	if (node.columns.length === 0) {
		return Array(4).fill({ code: '', name: '', isPrimaryKey: false })
	}

	return node.columns.map((column) => ({
		code: column.code,
		name: column.name,
	}))
}

export const getCommentAndDescription = (
	allKeysFromNodes: AllKeysFromNodes[],
	nodeId: string,
): { comment: string; description: string } => {
	const node = allKeysFromNodes.find((node) => node.id.toString() === nodeId)

	if (!node) {
		return { comment: '', description: '' }
	}

	return {
		comment: node.comment || '',
		description: node.description || '',
	}
}

const createDefaultNode = ($: typeof go.GraphObject.make) => {
	return $(
		go.Node,
		go.Panel.Auto,
		$(
			go.Shape,
			shapes.rectangle,
			{ fill: 'white' },
			new go.Binding('fill', 'color').makeTwoWay(),
			new go.Binding('fill', 'hasChanged', (hasChanged: boolean) =>
				hasChanged ? colors.nodes.hasChanged : colors.nodes.background,
			),
		),
		$(go.TextBlock, { margin: 8 }, new go.Binding('text')),
	)
}

const createTableNode = (
	$: typeof go.GraphObject.make,
	treeNodes: NativeMap<StructureDtoRedux>,
	allKeysFromNodes: AllKeysFromNodes[],
	removeNodeCallback?: (nodeId: number) => void,
	deleteNodeCallback?: (nodeId: number) => void,
	selectedNodeInfo?: (nodeId: number) => void,
	nodeCallback?: (nodeId: number) => void,
) => {
	return $(
		go.Node,
		'Auto',
		new go.Binding('location', 'loc', go.Point.parse).makeTwoWay(
			go.Point.stringify,
		),
		{
			locationSpot: go.Spot.Center,
			movable: true,
			contextMenu: $(
				'ContextMenu',
				$('ContextMenuButton', $(go.TextBlock, 'Remove table'), {
					click: (_e, obj) => {
						const node = obj.part
						if (node !== null && removeNodeCallback) {
							removeNodeCallback(node.data.key)
						}
					},
				}),
				$('ContextMenuButton', $(go.TextBlock, 'Delete table'), {
					click: (_e, obj) => {
						const node = obj.part
						if (node !== null && deleteNodeCallback) {
							deleteNodeCallback(node.data.key)
						}
					},
				}),
			),
			toolTip: $(
				'ToolTip',
				$(
					go.TextBlock,
					{ margin: 4, width: 140 },
					new go.Binding(
						'text',
						'',
						(data) =>
							`${data.text}:\n\n${getNodePath(treeNodes, data.key) || ''}`,
					),
				),
			),
			click: (obj) => {
				const clickedNode = obj.diagram.findPartAt(obj.documentPoint)
				if (clickedNode !== null && selectedNodeInfo) {
					selectedNodeInfo(clickedNode.data.key)
				}
			},
			doubleClick: (obj) => {
				const clickedNode = obj.diagram.findPartAt(obj.documentPoint)
				if (clickedNode !== null && nodeCallback) {
					nodeCallback(clickedNode.data.key)
				}
			},
		},
		$(
			go.Shape,
			shapes.rectangle,
			{
				stroke: colors.nodes.border,
				fill: colors.nodes.bodyBackground,
				strokeWidth: 1,
			},
			new go.Binding('fill', 'color').makeTwoWay(),
			new go.Binding('fill', 'hasChanged', (hasChanged: boolean) =>
				hasChanged ? colors.nodes.hasChanged : colors.nodes.background,
			),
		),
		$(
			go.Panel,
			'Vertical',
			{ background: colors.nodes.bodyBackground },
			new go.Binding('background', 'nodeBodyColor'),
			createTableHeader($),
			createTableBody($, allKeysFromNodes),
		),
	)
}

const createTableHeader = ($: typeof go.GraphObject.make) => {
	return $(
		go.Panel,
		'Vertical',
		$(
			go.Panel,
			'Auto',
			{ margin: 1, background: colors.nodes.headerBackground },
			new go.Binding('background', 'nodeHeaderColor'),
			$(
				go.TextBlock,
				{
					margin: 3,
					font: 'bold 16px sans-serif',
					width: 140,
					textAlign: 'center',
				},
				new go.Binding('text', '', (data) => {
					const { tableCodeVisibility, tableNameVisibility, code, text } = data
					if (tableCodeVisibility && tableNameVisibility) {
						return `${text} (${code})`
					} else if (tableCodeVisibility) {
						return `${code}`
					}
					return `${text}`
				}),
			),
		),
	)
}

const createTableBody = (
	$: typeof go.GraphObject.make,
	allKeysFromNodes: AllKeysFromNodes[],
) => {
	return $(
		go.Panel,
		'Vertical',
		{ background: colors.nodes.bodyBackground },
		new go.Binding('background', 'nodeBodyColor'),
		createTableColumns($, allKeysFromNodes),
		createTextBlockPanel(
			$,
			'commentsVisibility',
			(data) =>
				getCommentAndDescription(allKeysFromNodes, data.key.toString()).comment,
		),
		createTextBlockPanel(
			$,
			'descriptionsVisibility',
			(data) =>
				getCommentAndDescription(allKeysFromNodes, data.key.toString())
					.description,
		),
	)
}

const createTableColumns = (
	$: typeof go.GraphObject.make,
	allKeysFromNodes: AllKeysFromNodes[],
) => {
	return $(
		go.Panel,
		'Auto',
		{ margin: 1 },
		go.Panel.Table,
		new go.Binding('itemArray', '', (data) =>
			getAllColumns(allKeysFromNodes, data.key),
		),
		{
			itemTemplate: $(
				go.Panel,
				'TableRow',
				new go.Binding('row'),
				$(
					go.TextBlock,
					{
						margin: new go.Margin(0, 2),
						textAlign: 'left',
						stretch: go.GraphObject.Horizontal,
					},
					new go.Binding('text', '', (column, panel) => {
						let text = ''
						const nodeData = panel.part.data
						if (column) {
							if (
								nodeData.columnsNameVisibility &&
								nodeData.columnsCodeVisibility
							) {
								text = `${column.name} (${column.code})`
							} else if (nodeData.columnsNameVisibility) {
								text = column.name
							} else if (nodeData.columnsCodeVisibility) {
								text = column.code
							}
						}
						return text.trim()
					}),
				),
			),
		},
	)
}

const createTextBlockPanel = (
	$: typeof go.GraphObject.make,
	visibilityBindingName: string,
	textBindingCallback: (data: any) => string,
) => {
	return $(
		go.Panel,
		'Auto',
		{ margin: 0 },
		new go.Binding('background', 'nodeBodyColor'),
		$(
			go.TextBlock,
			{ row: 0, column: 0 },
			new go.Binding('text', '', textBindingCallback),
			new go.Binding('visible', visibilityBindingName),
		),
		new go.Binding('background', 'nodeBodyColor'),
	)
}

export const setupNodeTemplates = (
	allKeysFromNodes: AllKeysFromNodes[] | undefined,
	treeNodes: NativeMap<StructureDtoRedux>,
	nodeCallback?: (nodeId: number) => void,
	selectedNodeInfo?: (nodeId: number) => void,
	removeNodeCallback?: (nodeId: number) => void,
	deleteNodeCallback?: (nodeId: number) => void,
) => {
	const $ = go.GraphObject.make
	const nodeTemplates = new go.Map<string, go.Part>()

	nodeTemplates.add('', createDefaultNode($))
	nodeTemplates.add(
		DiagramTemplateType.Table,
		createTableNode(
			$,
			treeNodes,
			allKeysFromNodes || [],
			removeNodeCallback,
			deleteNodeCallback,
			selectedNodeInfo,
			nodeCallback,
		),
	)

	return nodeTemplates
}
