import go from 'gojs'

import { LinkRoutingType } from '@/enums'
import colors from '@/styles/diagramColors'

const arrows = {
	none: 'None',
	openTriangle: 'OpenTriangle',
	BackwardOpenTriangle: 'BackwardOpenTriangle',
}

// Type for go.GraphObject.make method
type MakeType = typeof go.GraphObject.make

// Type for common bindings
type BindingsType = Array<go.Binding | go.GraphObject>

// Type for link callback function
type LinkCallbackType = (node: number) => void

// Extending go.Link properties
type LinkExtendedProperties = Partial<go.Link & go.Part & go.GraphObject>

// Type for link settings
type LinkSettingsType = LinkExtendedProperties & {
	additionalBindings?: BindingsType
	additionalShapes?: BindingsType
}

export const setupLinkTemplates = (
	setSelectedLink: (link: number) => void,
	linkCallback: LinkCallbackType,
	selectedLinksRoutingType: string,
) => {
	const $ = go.GraphObject.make
	const linkTemplates = new go.Map<string, go.Link>()
	const commonBindings: BindingsType = [
		$(go.Shape, new go.Binding('stroke', 'linksColor').makeTwoWay()),
		$(
			go.TextBlock,
			{ background: colors.nodes.background },
			new go.Binding('text'),
		),
	]

	const createArrowBinding: BindingsType = [
		$(
			go.Shape,
			new go.Binding('stroke', 'color').makeTwoWay(),
			new go.Binding('stroke', 'hasChanged', (hasChanged) =>
				hasChanged ? colors.nodes.hasChanged : 'black',
			),
			{ fromArrow: arrows.BackwardOpenTriangle },
		),
	]

	const defaultLinkTemplate = $(
		go.Link,
		{
			routing:
				selectedLinksRoutingType === LinkRoutingType.Orthogonal
					? go.Link.Orthogonal
					: go.Link.Normal,
			click: (event) => {
				const clickedLink = event.diagram.findPartAt(event.documentPoint)
				if (clickedLink && setSelectedLink) {
					setSelectedLink(clickedLink.data)
				}
			},
			doubleClick: (event) => {
				const clickedLink = event.diagram.findPartAt(event.documentPoint)
				if (clickedLink && linkCallback) {
					linkCallback(clickedLink.data.from)
				}
			},
		},
		...commonBindings,
		...createArrowBinding,
	)

	linkTemplates.set('', defaultLinkTemplate)
	return linkTemplates
}
