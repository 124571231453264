import { addDiagramNode } from '@/components/Diagram/handlers/nodeHandlers'
import { DiagramTemplateType } from '@/components/Diagram/Templates/enums'
import {
	changeUserPermission,
	createChildNode,
	createNode,
	createNodeLock,
	deleteNode as deleteStructure,
	deleteNodeLock,
	deleteUserPermission,
	getNodesOfSpecificParent,
	getRootNodes,
	getStructurePermissions,
} from '@/endpoints'
import {
	NewStructureDto,
	NewStructureUserPermissionDto,
	StructureDto,
	StructureObjectDto,
	StructureUserPermissionDto,
} from '@/endpoints/models'
import { RootState } from '@/store'
import { addNewDiagramNode } from '@/store/modules/folder/actions'
import { apiCallAction, AppDispatch } from '@/store/utils'
import { isEditableNodeType } from '@/utils/nodes'

import { closeTabAndRelated, openTab } from '../tab/actions'
import { NodeSession } from '.'
import {
	NODE_ADD,
	NODE_ADD_SYSTEM,
	NODE_COLLAPSE,
	NODE_DELETE,
	NODE_DESELECT,
	NODE_EXPAND,
	NODE_IMPORT_STATE,
	NODE_LOAD_CHILDREN,
	NODE_LOAD_NESTED_CHILDREN,
	NODE_LOAD_PERMISSIONS,
	NODE_LOAD_SYSTEM,
	NODE_LOCK,
	NODE_REFRESH_TREE,
	NODE_SELECT,
	NODE_SELECT_MODE_CHANGE,
	NODE_UNLOCK,
} from './constants'
import { LoadNode, LoadNodeHistoryVersion } from './general-actions'

interface LoadSystemNodes {
	payload: StructureObjectDto[]
	type: typeof NODE_LOAD_SYSTEM
}

interface LoadNodeChildren {
	metadata: {
		parentId: number
	}
	payload: StructureObjectDto[]
	type: typeof NODE_LOAD_CHILDREN
}

interface LoadNestedNodeChildren {
	payload: StructureDto[]
	type: typeof NODE_LOAD_NESTED_CHILDREN
}

interface AddNode {
	payload: StructureDto
	type: typeof NODE_ADD | typeof NODE_ADD_SYSTEM
}

interface ExpandNode {
	key: number
	type: typeof NODE_EXPAND
}

interface CollapseNode {
	key: number
	type: typeof NODE_COLLAPSE
}

interface DeleteNode {
	metadata: {
		node: StructureDto
	}
	payload: void
	type: typeof NODE_DELETE
}

interface LockNode {
	metadata: {
		node: StructureDto
		userId: number
		userName: string
	}
	payload: void
	type: typeof NODE_LOCK
}

interface UnlockNode {
	metadata: {
		node: StructureDto
	}
	payload: void
	type: typeof NODE_UNLOCK
}

interface NodeImportState {
	session: NodeSession
	type: typeof NODE_IMPORT_STATE
}

interface NodeLoadPermissions {
	metadata: {
		nodeId: number
	}
	payload: StructureUserPermissionDto[]
	type: typeof NODE_LOAD_PERMISSIONS
}

interface NodeRefreshTree {
	type: typeof NODE_REFRESH_TREE
}

interface SelectNodeModeChange {
	metadata: {
		selectMode: boolean
	}
	type: typeof NODE_SELECT_MODE_CHANGE
}
interface SelectNodes {
	metadata: {
		selectedNodes: number[]
	}
	type: typeof NODE_SELECT
}

interface DeselectNodes {
	metadata: {
		deselectedNodes: number[]
	}
	type: typeof NODE_DESELECT
}

export const loadSystemNodes = () =>
	apiCallAction<LoadSystemNodes>(() => getRootNodes(), NODE_LOAD_SYSTEM)

export const loadNodeChildren = (id: number) =>
	apiCallAction<LoadNodeChildren>(
		() => getNodesOfSpecificParent(id),
		NODE_LOAD_CHILDREN,
		{ parentId: id },
	)

export const loadNestedNodeChildren =
	(id: number) => async (dispatch: AppDispatch) => {
		const children = await dispatch(
			apiCallAction<LoadNestedNodeChildren>(
				() => getNodesOfSpecificParent(id, { recursive: true }),
				NODE_LOAD_NESTED_CHILDREN,
			),
		)

		return children
	}

export const selectNode =
	(node: StructureDto) =>
	async (dispatch: AppDispatch, getValues: () => RootState) => {
		const selectMode = getValues().node.selectMode

		if (!selectMode) {
			dispatch(selectModeChange(true))
		}

		dispatch({
			type: NODE_SELECT,
			metadata: {
				selectedNodes: [node.id],
			},
		})
	}

export const selectAllNode =
	(node: StructureDto) =>
	async (dispatch: AppDispatch, getValues: () => RootState) => {
		const selectMode = getValues().node.selectMode

		const isFolder =
			getValues().node.nodes[node.id]?.type ===
			StructureObjectDto.TypeEnum.FOLDER

		if (!selectMode) {
			dispatch(selectModeChange(true))
		}

		if (isFolder) {
			const nodesToSelect = await dispatch(loadNestedNodeChildren(node.id))
			const nestedNodeIds = nodesToSelect.map((n) => n.id)

			dispatch({
				type: NODE_SELECT,
				metadata: {
					selectedNodes: [node.id, ...nestedNodeIds],
				},
			})
		}

		dispatch({
			type: NODE_SELECT,
			metadata: {
				selectedNodes: [node.id],
			},
		})
	}

export const deselectNodes =
	(nodeIds: number[]) => async (dispatch: AppDispatch) => {
		dispatch({
			type: NODE_DESELECT,
			metadata: {
				deselectedNodes: nodeIds,
			},
		})
	}

export const deselectAllNode =
	(node: StructureDto) =>
	async (dispatch: AppDispatch, getValues: () => RootState) => {
		const { children } = getValues().node

		const getChildren = (parentId: number) => {
			let result = [parentId]
			const parentChildren = children[parentId]

			if (!parentChildren) {
				return []
			}

			result = [...result, ...parentChildren]

			parentChildren?.forEach((child) => {
				const ch = getChildren(child)

				if (ch) {
					result = [...result, ...ch]
				}
			})

			return result
		}

		const nodeToDeselect = getChildren(node.id)

		dispatch({
			type: NODE_DESELECT,
			metadata: {
				deselectedNodes: [...nodeToDeselect, node.id],
			},
		})
	}

export const selectModeChange =
	(selectMode: boolean) => async (dispatch: AppDispatch) =>
		dispatch({
			type: NODE_SELECT_MODE_CHANGE,
			metadata: {
				selectMode,
			},
		})

export const addNode = (parentId: number, data: NewStructureDto) => {
	return async (dispatch: AppDispatch) => {
		const newNode = await dispatch(
			apiCallAction<AddNode>(() => createChildNode(parentId, data), NODE_ADD),
		)

		const isEditable = isEditableNodeType(data.type)

		dispatch(loadNodeChildren(parentId))
		dispatch(expandNode(parentId))

		if (isEditable) {
			dispatch(lockNode(newNode))
		}

		dispatch(openTab(newNode, false, isEditable))
	}
}

export const addNodeInDiagramContextMenu = (
	parentId: number,
	data: NewStructureDto,
	diagramProps?: {
		diagram: any
		isDiagramAddModal?: boolean
		node: StructureDto
		selectedTabDetailId?: number
	},
) => {
	const { node, selectedTabDetailId, diagram } = diagramProps || {}
	return async (dispatch: AppDispatch) => {
		const newNode = await dispatch(
			apiCallAction<AddNode>(() => createChildNode(parentId, data), NODE_ADD),
		)

		const nodePayload = {
			key: newNode?.id,
			category: DiagramTemplateType.Table,
			text: newNode.name,
			code: newNode.code,
			loc: '',
			selectedTabDetailId,
		}

		addDiagramNode(diagram?.ref, newNode?.id, newNode, [], '')

		dispatch(addNewDiagramNode(node?.id as number, nodePayload))
	}
}

export const addSystemNode =
	(data: NewStructureDto) => async (dispatch: AppDispatch) => {
		const newSystemNode = await dispatch(
			apiCallAction<AddNode>(() => createNode(data), NODE_ADD_SYSTEM),
		)

		dispatch(loadSystemNodes())
		dispatch(openTab(newSystemNode, false))
	}

export const deleteNode =
	(node: StructureDto) => async (dispatch: AppDispatch) => {
		dispatch(closeTabAndRelated(node))

		await dispatch(
			apiCallAction<DeleteNode>(() => deleteStructure(node.id), NODE_DELETE, {
				node,
			}),
		)

		if (node.type && node.type !== StructureObjectDto.TypeEnum.SYSTEM) {
			dispatch(loadNodeChildren(node.parentStructureId as number))
		} else {
			dispatch(loadSystemNodes())
		}
	}

export const lockNode =
	(node: StructureDto) =>
	async (dispatch: AppDispatch, getValues: () => RootState) => {
		const user = getValues().auth.user

		if (!user) {
			throw new Error(`There is no authenticated user`)
		}

		await dispatch(
			apiCallAction<LockNode>(() => createNodeLock(node.id), NODE_LOCK, {
				node,
				userId: user.id,
				userName: user.compositeName,
			}),
		)

		dispatch(loadNodeChildren(node.parentStructureId as number))
	}

export const unlockNode =
	(node: StructureDto) => async (dispatch: AppDispatch) => {
		await dispatch(
			apiCallAction<UnlockNode>(() => deleteNodeLock(node.id), NODE_UNLOCK, {
				node,
			}),
		)

		dispatch(loadNodeChildren(node.parentStructureId as number))
	}

export const expandNode = (key: number): ExpandNode => ({
	type: NODE_EXPAND,
	key,
})

export const collapseNode = (key: number): CollapseNode => ({
	type: NODE_COLLAPSE,
	key,
})

export const loadNodeState =
	(session: NodeSession) =>
	(dispatch: AppDispatch, getValues: () => RootState) => {
		const updated = { ...session }
		const children = getValues().node.children

		// Normalize expanded nodes
		const expanded = new Set<number>()
		session.expanded.forEach((id) => expanded.add(id))
		updated.expanded = Array.from(expanded)

		dispatch({
			type: NODE_IMPORT_STATE,
			session: updated,
		})

		return Promise.all(
			getValues()
				.node.expanded.filter((nodeId) => !children[nodeId])
				.map((nodeId) =>
					dispatch(loadNodeChildren(nodeId)).catch(() => {
						dispatch(collapseNode(nodeId))
					}),
				),
		)
	}

export const loadNodePermissions = (id: number) =>
	apiCallAction<NodeLoadPermissions>(
		() => getStructurePermissions(id),
		NODE_LOAD_PERMISSIONS,
		{ nodeId: id },
	)

export const setNodePermission = (
	nodeId: number,
	userId: number,
	permissionCode: NewStructureUserPermissionDto.PermissionCodeEnum,
) =>
	apiCallAction(() => changeUserPermission({ permissionCode }, nodeId, userId))

export const removeNodePermission = (nodeId: number, userId: number) =>
	apiCallAction(() => deleteUserPermission(nodeId, userId))

export const nodeRefreshTree =
	() => async (dispatch: AppDispatch, getValues: () => RootState) => {
		await dispatch(loadSystemNodes())

		await Promise.all(
			getValues().node.expanded.map(async (nodeId) => {
				try {
					await dispatch(loadNodeChildren(nodeId))
				} catch (e) {
					console.warn(`Failed to reload children for ${nodeId}:`, e)
				}
			}),
		)
	}

export type Actions =
	| LoadSystemNodes
	| LoadNodeChildren
	| LoadNestedNodeChildren
	| AddNode
	| ExpandNode
	| CollapseNode
	| DeleteNode
	| LockNode
	| UnlockNode
	| NodeImportState
	| LoadNode
	| NodeLoadPermissions
	| NodeRefreshTree
	| SelectNodes
	| DeselectNodes
	| SelectNodeModeChange
	| LoadNodeHistoryVersion
