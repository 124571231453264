import go from 'gojs'
import { ReactDiagram } from 'gojs-react'
import { MutableRefObject } from 'react'
import { useDispatch } from 'react-redux'

import { addDiagramNode } from '@/components/Diagram/handlers/nodeHandlers'
import { DiagramTemplateType } from '@/components/Diagram/Templates/enums'
import { StructureDto } from '@/endpoints/models'
import { useAppStore } from '@/hooks'
import { TREE_PANEL_DRAG_DROP_NODE_ID } from '@/pages/User/pages/Home/components/TreePanel/drag'
import { addNewDiagramNode } from '@/store/modules/folder/actions'

interface DiagramOptions {
	diagram: {
		exportPng: () => void
		exportSvg: () => void
		makeAutoLayout: () => void
		ref: MutableRefObject<ReactDiagram | null>
	}
	node: StructureDto
	selectedTabDetailId?: number
}

export const useReactDiagramDrop = ({
	diagram,
	selectedTabDetailId,
	node,
}: DiagramOptions) => {
	const dispatch = useDispatch()
	const allTreeNodes = useAppStore((state) => state?.node?.nodes)

	const handleDrop = (e: React.DragEvent) => {
		e.preventDefault()
		e.stopPropagation()

		const droppedNodeId = Number(
			e.dataTransfer.getData(TREE_PANEL_DRAG_DROP_NODE_ID),
		)
		const droppedNode = allTreeNodes[droppedNodeId] as StructureDto

		const instanceDiagram = diagram.ref.current?.getDiagram()
		const model = instanceDiagram?.model as go.GraphLinksModel

		if (!model || !instanceDiagram || !instanceDiagram.div) return

		const diagramRect = instanceDiagram.div.getBoundingClientRect()
		const offsetX = e.clientX - diagramRect.left
		const offsetY = e.clientY - diagramRect.top

		// Convert the client coordinates to diagram coordinates
		const docPoint = instanceDiagram.transformViewToDoc(
			new go.Point(offsetX, offsetY),
		)

		const loc = `${docPoint?.x} ${docPoint?.y}`

		const nodePayload = {
			key: droppedNode?.id,
			category: DiagramTemplateType.Table,
			text: droppedNode.name,
			code: droppedNode.code,
			loc,
			selectedTabDetailId,
		}

		addDiagramNode(diagram.ref, droppedNodeId, droppedNode, [], loc)

		dispatch(addNewDiagramNode(node.id, nodePayload))
	}

	const handleDragOver = (e: React.DragEvent) => {
		e.preventDefault()
		e.stopPropagation()
	}

	const handleDragLeave = (e: React.DragEvent) => {
		e.preventDefault()
		e.stopPropagation()
	}

	return {
		handleDrop,
		handleDragOver,
		handleDragLeave,
	}
}
