import { ReactDiagram } from 'gojs-react'
import { MutableRefObject, RefObject } from 'react'

import { StructureDto } from '@/endpoints/models'
import { StructureDtoRedux } from '@/store/modules/node'
import { NodeData } from '@/types'
import { NativeMap } from '@/utils'

const categories = {
	default: '',
	table: 'table',
}

export type Category = (typeof categories)[keyof typeof categories]

export type Node = {
	bodyColor?: string
	/**
	 * should be used to apply template
	 */
	category?: Category
	color?: string
	hasChanged?: boolean
	headerColor?: string
	key: number
	text?: string
	textBackground?: string
}

export type Link = {
	color?: string
	/**
	 * the key of node
	 */
	from: number
	key: number
	text?: string
	/**
	 * the key of node
	 */
	to: number
}

export type NewDiagramProps = {
	diagram?: any
	hasPalette?: boolean
	isEditMode?: boolean
	node: StructureDto
	nodeCallback?: (nodeId: number) => void
	nodeDataArray: NodeData[]
	onModelChange: (value: any) => void
	parsedLinks: ParsedLink[]
	properties: any
	saveProperties?: (properties: any) => void
	selectedTabDetailId?: number
}

export type ValidateDiagramProps = {
	diagram: {
		exportPng: () => void
		exportSvg: () => void
		makeAutoLayout: () => void
		ref: React.MutableRefObject<ReactDiagram | null>
	}
	isEditMode: boolean | undefined
	nodeId: number
	onModelChange: () => void
	onValidationErrors: (errors: ValidationError[]) => void
	setShowModal: (value: boolean) => void
}

export enum ValidationErrorType {
	NODE_NOT_EXIST,
	TEXT_MISMATCH,
	LINK_INVALID,
}

export type ValidationError = {
	message: string
	nodeId: number
	nodeName: string
	type:
		| ValidationErrorType.NODE_NOT_EXIST
		| ValidationErrorType.TEXT_MISMATCH
		| ValidationErrorType.LINK_INVALID
}

export type DiagramModalsProps = {
	folderNode?: StructureDto
	handleClose: () => void
	handleUpdateDiagram: () => void
	isAddNewNode: boolean
	nodeToDelete?: StructureDto
	setIsAddNewNode: (show: boolean) => void
	setShowDeleteModal: (show: boolean) => void
	showDeleteModal: boolean
	showModal: boolean
	validationErrors: ValidationError[]
}

export type DiagramPropertiesPanelProps = {
	diagramRef: RefObject<ReactDiagram>
	nodeId: number
	saveProperties: ((properties: any) => void) | undefined
	selectedDiagram?: boolean
	selectedLink?: Link | null | number
	selectedNode?: Node | null
	selectedTabDetailId: number
}

export type DiagramPropertiesProps = {
	diagramRef: RefObject<ReactDiagram>
	handlePropertiesTitle: (title: string) => void
	nodeId: number
	saveProperties: ((properties: any) => void) | undefined
	selectedTabDetailId: number
}

export type NodePropertiesProps = {
	diagramRef: RefObject<ReactDiagram>
	getAllNodesFromTree: NativeMap<StructureDtoRedux>
	handlePropertiesTitle: (title: string) => void
	nodeId: number
	propertiesWidth: number
	selectedNode: Node
	selectedTabDetailId: number
}

export interface DiagramPropertiesContentProps {
	contents: ContentsProps[]
	propertiesWidth: number
}
export interface ContentsProps {
	contentValue: React.JSX.Element
	filter?: string
	id: number
	isGrouped?: boolean
	label: string
	type?: string
}

export type LinkPropertiesProps = {
	diagramRef: MutableRefObject<ReactDiagram | null>
	handlePropertiesTitle: (title: string) => void
	nodeId: number
	propertiesWidth: number
	selectedLink: any
	selectedTabDetailId: number
}

export interface ParsedLink {
	color?: string
	from: string | number
	hasChanged?: boolean
	routingType?: string
	text?: string
	to: string | number
}

export interface ParsedNode {
	category?: string
	key: number | undefined
}

export type LineColorType =
	| 'intervalHorizontal'
	| 'intervalVertical'
	| 'vertical'
	| 'horizontal'

export interface LocalDiagramPropertiesProps {
	gridCellSize?: number
	gridHorizontalLineColor?: string
	gridIntervalHLineColor?: string
	gridIntervalVLineColor?: string
	gridVerticalLineColor?: string
	isGridVisible?: boolean
	isTableCodeVisible?: boolean
	isTableNameVisible?: boolean
	linksColor?: string
	linksRoutingType?: string
	nodeBodyColor?: string
	nodeHeaderColor?: string
}

export interface LocalSpecificNodeProps {
	specificNodeBodyColor?: string
	specificNodeHeaderColor?: string
}
