import { useCallback, useMemo, useState } from 'react'

import { DiagramWrapper, MessageModal } from '@/components'
import { DiagramProvider } from '@/components/Diagram/context/DiagramProvider'
import { TabContext } from '@/context/TabContext/TabContext'
import { getSystemReleases } from '@/endpoints'
import { useApi } from '@/endpoints/hooks'
import { StructureDto } from '@/endpoints/models'
import { ApiError } from '@/endpoints/utils'
import { TabName } from '@/enums'
import { useAppContext, useAppDispatch, useAppStore } from '@/hooks'
import { DeploymentPackageDetail } from '@/pages/User/pages/Home/pages/SystemDetail/pages/DeploymentPackages/components/DeploymentPackageDetail'
import { loadNodeChildren } from '@/store/modules/node/actions'
import { cancelEditingTab, closeTab } from '@/store/modules/tab/actions'
import { OpenedTab } from '@/store/modules/tab/types'

import { CancelEditModal } from '../CancelEditModal'
import { DetailTab } from '../DetailTab/DetailTab'
import { TabNavigation } from './components/TabNavigation'
import { Container, TabContent } from './styles'
import { getDpDetailEditMode } from './utils'

export const TabsDetailView = () => {
	const { t } = useAppContext()
	const dispatch = useAppDispatch()
	const nodes = useAppStore((state) => state.node.nodes)
	const openedTabs = useAppStore((state) => state.tab.openedTabs)
	const selectedTab = useAppStore((state) => state.tab.selectedTab)

	const [message, setMessage] = useState(null)

	const [nodesToConfirm, setNodesToConfirm] = useState([] as StructureDto[])

	const currentTab =
		selectedTab !== undefined ? openedTabs[selectedTab] : undefined

	const currentNode = currentTab ? nodes[currentTab.nodeId] : undefined

	const { invalidate } = useApi(
		getSystemReleases(currentTab?.customTabProps?.parentNode.id as number),
	)

	const reloadReleases = useCallback(() => {
		invalidate()
	}, [])

	const handleSaveError = async (e: unknown) => {
		if (currentNode) {
			dispatch(cancelEditingTab(currentNode as StructureDto))
			await dispatch(loadNodeChildren(currentNode.parentStructureId as number))
		}

		if (e instanceof ApiError && e.response.status === 423) {
			const data = await e.response.json()
			setMessage(data.errorDescription)
		} else {
			throw e
		}
	}

	const renderTabDetail = useMemo(() => {
		switch (currentTab?.customTabProps?.customTabName) {
			case TabName.DeploymentPackage:
				return (
					<DeploymentPackageDetail
						node={currentTab.customTabProps.parentNode}
						selectedTabDetailId={currentTab.customTabProps.id}
						isEditMode={getDpDetailEditMode(openedTabs, currentTab) as boolean}
						reloadReleases={reloadReleases}
					/>
				)
			case TabName.Diagram:
				return (
					<DiagramProvider
						isEditMode={true}
						selectedTabDetailId={currentTab.customTabProps.id}
						node={currentTab.customTabProps.parentNode}
					>
						<DiagramWrapper />
					</DiagramProvider>
				)

			default:
				if (currentNode && currentTab) {
					return (
						<DetailTab
							tab={currentTab}
							node={currentNode as StructureDto}
							key={`${currentTab.nodeId}`}
						/>
					)
				}
				return <></>
		}
	}, [currentTab, currentNode, openedTabs, reloadReleases])

	const handleCloseConfirm = () => {
		setNodesToConfirm(nodesToConfirm.slice(1))
	}

	const handleCloseRequest = (nodes: StructureDto[]) => {
		setNodesToConfirm(nodes)
	}

	if (!openedTabs || openedTabs.length === 0) {
		return <></>
	}

	return (
		<TabContext.Provider
			value={{ tab: currentTab as OpenedTab, onSaveError: handleSaveError }}
		>
			<Container role="main">
				<TabNavigation onConfirm={handleCloseRequest} />
				<TabContent role="tabpanel">{renderTabDetail}</TabContent>
				<MessageModal
					title={t('ERROR_WHEN_SAVING')}
					opened={!!message}
					onClose={() => setMessage(null)}
					message={message || ''}
					type="warn"
				/>

				{nodesToConfirm.length > 0 && (
					<CancelEditModal
						node={nodesToConfirm[0]}
						onConfirm={async () => {
							dispatch(closeTab(nodesToConfirm[0].id))
						}}
						onClose={handleCloseConfirm}
					/>
				)}
			</Container>
		</TabContext.Provider>
	)
}
